#sessaoThumbnail {
  padding: 0;
  .descricao {
    text-align: justify;
  }
  .redesSociais {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25px;
    z-index: 10;
    display: flex;
    align-items: center;

    i {
      font-size: 20px;
      color: #071a77;
    }
  }

  img {
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }

  .carousel-caption {
    text-align: inherit !important;
    right: 50%;

    h3 {
      color: #071a77;
    }

    .btn-primary {
      background-color: #071a77;
      border-color: #071a77;
    }
  }
}

.carousel-indicators [data-bs-target] {
  width: 12px !important;
  height: 11px !important;
  border-radius: 100%;
  background-color: #071a77 !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  border-radius: 5px !important;
  height: 2.3rem !important;
  background-color: #071a77;
}

@media (max-width: 991.98px) {
  #sessaoThumbnail {
    img {
      height: 100vw;
      object-fit: contain;
    }
    .redesSociais {
      display: none;
    }
    .descricao {
      display: none;
    }

    .carousel-caption {
      right: 30%;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      display: none;
    }
  }
}
