#secaoSolucoes {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ColCard {
    .Cards {
      border: none;
      padding-top: 75px;

      img {
        width: 17%;
      }
      .Titulo {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.05em;
        color: #071a77;
        text-transform: uppercase;
      }
      .Texto {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.04em;
        color: #2e2d2b;
        padding-top: 30px;
        text-align: justify;
      }
    }
  }

  .ColText {
    h2 {
      font-weight: 700;
      font-size: 60px;
      line-height: 82px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: #071a77;
    }
    p {
      font-weight: 400;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.05em;
      color: #2d2d2b;
      padding-top: 30px;
    }
  }
}
