#secaoSobre {
  padding: 80px 0;
  background-color: #071a77;
  width: 100%;
  display: flex;
  align-items: center;

  .ColImg {
    img {
      width: 30%;
      border-radius: 20px;
      margin-bottom: 38px;
    }
  }

  .ColText {
    .textos {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        font-weight: 700;
        font-size: 60px;
        line-height: 82px;
        letter-spacing: 0.05em;
        color: #ffffff;
      }

      p {
        padding-top: 30px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.04em;
        text-align: justify;
        color: #ffffff;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #secaoSobre {
    .ColImg {
      img {
        width: 100%;
      }
    }
    .ColText {
      .textos {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
          margin-top: 10px;
          font-weight: 700;
          font-size: 50px;
          line-height: 82px;
          letter-spacing: 0.05em;

          color: #ffffff;
        }

        p {
          padding-top: 30px;
          font-weight: 400;
          line-height: 25px;
          letter-spacing: 0.04em;
          color: #ffffff;
        }
      }
    }
  }
}
