#secaoContato {
  padding: 80px 0;

  .alertBox {
    margin-top: 20px;
  }
  .ColText {
    h2 {
      font-weight: 700;
      font-size: 60px;
      line-height: 82px;
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;
      color: #071a77;
    }
    margin-bottom: 30px;
  }

  .ColInput {
    padding: 15px;
    input {
      height: 50px;
    }
  }

  .ColInputDescription{
    padding: 15px;
  }

  .ColButton {
    display: flex;
    justify-content: right;
    align-items: center;
    button {
      background-color: #071a77;
      border-radius: 0;
      height: 60px;
      width: 15rem;
      font-weight: 400;
      font-size: 18px;
      line-height: 35px;
      letter-spacing: 0.11em;
      color: #ffffff;
      border: #071a77;
      border-radius: 10px;
    }
  }
}

@media(max-width: 991.98px){
  .ColRecaptcha, .ColButton {
    margin-bottom: 15px;
    display: flex !important;
    justify-content: center !important;
  }

  .ColButton button {
    width: 100% !important;
  }
}