.scrollOn {
  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  #sessaoMenu {
    box-shadow: none;
  }
}

.scrollOff {
  background-color: transparent;
}

#sessaoMenu {
  background-color: white;
  border-radius: 10px;
  .logotipo {
    width: 60%;
  }
  .navbar-collapse {
    flex-grow: 0;

    .botaoMenu {
      font-size: 16px;
      color: #071a77;
    }

    .btn {
      background-color: #071a77;
      border-color: #071a77;
      margin-left: 15px;
      .btnCliente {
        text-decoration: none;
        color: #FFF;
      }
    }
  }
}

@media (max-width: 991.98px) {
  #sessaoMenu {
    .navbar-brand {
      width: 50%;

      .logotipo {
        width: 100%;
      }
    }
  }

  .scrollOff {
    background-color: white;
  }
}
