#Footer {
  display: flex;
  background-color: #071a77;
  color: white !important;
  padding-top: 30px;

  .row {
    padding: 10px 0;
    border-bottom: 1px solid white;

    .iconesRodape {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      i {
        font-size: 22px;
      }

      a {
        color: white;
      }
    }

    .tituloItem {
      font-weight: 700;
      font-size: 18px;
    }

    .links {
      color: inherit;
      text-decoration: none;
      font-size: 16px;
    }
  }

  .direitosAutorais {
    color: white;
    .rowCopyright {
      border-bottom: none;
    }
    .direitos {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .textoDireitos {
        margin: 0;
        font-size: 13px;
        color: #fff;
      }
    }

    .tytotech {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 0;

      .containerTyto {
        display: flex;
        align-items: center;
        text-decoration: none;
        p {
          margin-left: 10px;
          margin-bottom: 0;
          font-size: 13px;
          color: #fff;
        }
      }

      .textoTytotech {
        margin: 0;
      }
    }
  }

  a#btn-whatsapp {
    text-decoration: none;
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 15px;
    left: 15px;
    background-color: #1ebea5;
    border-radius: 100px;
    text-align: center;
    font-size: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 30px;
      color: #ffffff;
    }
  }
}

@media (max-width: 991.98px) {
  #Footer {
    .itemRodape {
      text-align: center;
      margin-top: 20px;
    }
    .tytotech {
      justify-content: center !important;
    }
  }
}
