#sessaoInsta {
  padding: 80px 0;
  .ColText {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 15px;
    .linkInstagram {
      display: flex;
      text-decoration: none;
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.05em;
        color: #000000;
      }

      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
    }
  }

  .ColCarroselMobile {
    display: none;
  }

  .ColCarrosel {
    img {
      width: 33%;
      padding: 10px;
    }
  }
}

@media (max-width: 991.98px) {
  #sessaoInsta {
    padding: 80px 0;
    .ColText {
      display: flex;
      align-items: center;
      justify-content: left;
      padding: 15px;

      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.05em;
        color: #000000;
      }

      img {
        width: 4%;
        margin-right: 20px;
      }
    }

    .carousel-indicators [data-bs-target] {
      margin-left: 10px;
      margin-right: 10px;
    }

    .ColCarroselMobile {
      display: block;

      img {
        width: 100%;
        align-items: center;
      }

      // a{
      //   display: none;
      // }
    }

    .ColCarrosel {
      display: none;
      img {
        width: 32%;
        height: 30rem;
        align-items: center;
        padding: 15px;
        margin-left: 10px;
      }
    }
  }
}
